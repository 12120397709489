import { RecursivePartial } from '../index'
import { GeoJson, ID, ISOString, PointGeoJson } from '../scalars'
// eslint-disable-next-line import/no-cycle
import { Company } from './companies'
import {
  AdrClassSlug, CargoAlfaTypeSlug,
  CargoTypeSlug,
  CurrencySlug,
  ExtraEquipmentSlug,
  LoadingTypeSlug,
  PaymentConditionSlug,
  PaymentMethodSlug,
  PointTypeSlug, RouteStartDateOffsetDays,
  ShippingModeSlug,
  TransportTypeSlug,
  TruckBodySlug,
} from './knowledge/exchange'
import { Profile } from './profiles'

export type CreateOfferResponse = Offer
export type UpdateOfferResponse = Offer
export type FlexiblePolyline = string

export enum CreationMethod {
  MANUAL = 'manual',
}

export enum MileageFormat {
  KM = 'km',
  MILES = 'miles',
}

export enum OfferStatus {
  NOT_PUBLISHED = 'not_published',
  PUBLISHED = 'published',
  AWAIT_CONFIRM = 'await_confirm',
  CONFIRMATION = 'confirmation',
  DEAL_MADE = 'deal_made',
  CLOSED = 'closed',
  DELETED = 'deleted',
}

export enum ExtraOfferStatus {
  OPENED = 'opened', // only for view tabs in MyOffers page
}

export enum AssigneeContactMethod {
  PHONE = 'phone',
  EMAIL = 'email',
  CHAT = 'chat',
}

export enum OfferType {
  CARGO = 'cargo',
  TRANSPORT = 'transport',
}

export interface BidPrivacy {
  allow_view_bidder?: boolean
  allow_view_quote?: boolean
  allow_view_comment?: boolean
}

export interface BaseOffer {
  _id: string
  assignee_users: AssigneeContact[] | null
  attachments: string[] | null
  autoclose_at: string
  closed_at: string
  company_id: Company['id'] | null
  complaints: {
    created_at: string
    user_id: string
  }[]
  complaints_count: number
  created_at: string
  creation_method: CreationMethod
  creation_profile_id: Profile['id']
  deleted_at: string | null
  description: string | null
  geo_process_state?: GeoState
  is_autoclosed: boolean
  is_public: boolean
  is_private: boolean
  is_publication_autocanceled: boolean
  last_matched_at: string
  // map_routes: deprecated
  matches_exact?: Offer['_id'][] | null
  matches_exact_count?: number | null
  matches_partial?: Offer['_id'][] | null
  matches_partial_count?: number | null
  offer_name: string
  offer_type: OfferType
  organization: Organization | null
  payment: Payment
  publication_lifetime: PublicationLifetime
  bids_info: BidsInfo | null
  private_partners: Company['id'][] | null
  publication_autocancel_at: string
  publication_autocancel_type: 'time_expiration' | 'assignee_removed' | 'moderator'
  published_at: string
  route: RoutePoint[]
  route_way?: RouteWay
  prev_status: OfferStatus | null
  status: OfferStatus
  status_changed_at: string
  update_visibility_at: string
  updated_at: string
  views_count: number
  watching_now_count?: number
  partners_selection_dependent_offer_id: Offer['_id'] | null
  partners_selection_original_offer_id: Offer['_id'] | null
  is_partners_selection_dependent_offer_ever_set: boolean | null
  is_partners_selection_dependent_offer: boolean | null
  is_public_duplicate: boolean | null
  is_partners_selection: boolean | null
  bid_privacy?: BidPrivacy,
}

export interface CargoOffer extends BaseOffer {
  cargo_units: CargoUnit[] | null
  transportation_requirement: TransportationRequirement
}

export interface TransportOffer extends BaseOffer {
  transport: TransportInfo
}

export type Offer = CargoOffer | TransportOffer

export interface GetOfferRequest {
  id: Offer['_id'],
  active_space_company_id?: Company['id'] | null
  polygon?: 1
  way_polyline?: 1
}

export interface ExchangePagination {
  sort?: 'asc' | 'desc'
  page?: number
  records?: number
}

export interface CreateOfferRequestBase {
  creation_method: CreationMethod.MANUAL
  organization: { id: ID, name: string } | null
  assignee_users: Array<{
    id: Profile['id']
    contact_methods: { method: AssigneeContactMethod }[]
  }>
  is_public: boolean
  is_private: boolean
  is_public_duplicate: boolean
  is_partners_selection: boolean
  private_partners: Company['id'][]
  is_all_private_partners: boolean // flag for backend only
  offer_type: OfferType
  description: string | null
  attachments: URL[]
  route: Array<Omit<RoutePoint, '_id'>>,

  cargo_units: Array<Omit<CargoUnit, '_id'>> | null
  transportation_requirement: TransportationRequirement | null,
  transport?: TransportInfo
  payment: Partial<Payment>
  publication_lifetime?: Pick<PublicationLifetime, 'lifetime' | 'is_first_bid'>
  bid_privacy?: BidPrivacy | null
}

export interface CreateOfferRequest extends CreateOfferRequestBase {
  status: OfferStatus.PUBLISHED | OfferStatus.NOT_PUBLISHED
  // company_id: Company['id'] | null
}

export interface UpdateOfferRequest extends RecursivePartial<Omit<CreateOfferRequest, 'status'>> {
  id: string
  status?: OfferStatus
  deleted_at?: string | null
}

export interface CreateDuplicatesRequest {
  offer: Pick<Offer, '_id'>,
  count: number
}

export interface RoutePointDatetime {
  date: string | null // YYYY-MM-DD
  time: string | null // HH:MM:SS
  datetime?: string // usually exist on offer output
}

export interface RoutePoint {
  _id: string
  end_datetime: RoutePointDatetime | null
  loading_type: LoadingTypeSlug[] | null
  location: {
    disputed?: boolean

    osm_id: string | null
    osm_type: string | null

    address: {
      title: string | null
      name: string | null
      lang: string | null
      country_code: string | null
      country_name: string | null
      county: string | null
      state_name: string | null
      state_code: string | null
      is_ru_us_in: boolean | null
      city: string | null
      district: string | null
      sub_district: string | null
      street: string | null
      postal_code: string | null
      house_number: string | null
      time_zone: string | null
    } | null,
    is_radius: boolean
    radius: number | null
    position?: GeoJson | null
    position_type: string | null
    access_point: PointGeoJson | null
    map_view: {
      west: number
      south: number
      east: number
      north: number
    } | null
  }
  order: number
  point_timezone?: string // after creation
  point_type: PointTypeSlug | null
  start_datetime: RoutePointDatetime | null
  time_zone: string | null
  start_time_offset_days?: RouteStartDateOffsetDays
}

export interface Payment {
  is_not_quote: boolean // deprecated
  currency: CurrencySlug
  quote_is_vat: boolean | null
  quote: string | null
  extra_quote: string | null
  universal_quote: string | null
  vat: string | null // auction vat percent
  pure_quote: string | null
  quote_mileage: string | null
  mileage_format: MileageFormat | null
  is_prepay: boolean
  prepay_percent: number | null
  prepay_amount: string | null // deprecated
  payment_condition: PaymentConditionSlug | null
  payment_period: number | null
  payment_method: PaymentMethodSlug | null
  bid_mode: BidMode
  bid_is_no_vat: boolean | null
  bid_is_vat: boolean | null
  bid_auction_step: string | null
  bid_is_auto_accept: boolean | null
  bid_is_not_unique: boolean | null
  is_quote_autochange: boolean | null
  quote_autochange: {
    is_increase: boolean | null
    step: string | null
    changed_at: string | null
    timeframe: number // ms
    quote_limit: string | null
  }
  bid_confirm_timeframe: number | null // ms
  allow_first_bid_no_step: boolean | null
  universal_currency_code: CurrencySlug // for new features, currently same as payment currency
}

export interface PublicationLifetime {
  is_first_bid: boolean | null
  lifetime: number // seconds
  is_late_bid_prolong: boolean | null
  prolong_step: number // ms
  prolong_times: number
}

export interface CargoUnit {
  _id: ID
  name: string | null
  type: CargoTypeSlug | null
  quantity: number | null
  weight: number | null
  volume: number | null
  adr_class: AdrClassSlug | null
  length: number | null
  width: number | null
  height: number | null
  temperature_min_c: number | null
  temperature_max_c: number | null

  alf_type?: CargoAlfaTypeSlug | null
  alf_mark?: string | null
  alf_model?: string | null
}

// export enum CargoUnitAlfType {
//   la = 'la',
//   ga = 'ga',
//   st = 'st',
//   on = 'on'
// }

export interface TransportationRequirement {
  shipping_mode: ShippingModeSlug | null
  type: TransportTypeSlug | null
  quantity: number | null
  body: TruckBodySlug[]
  equipment: ExtraEquipmentSlug[] | null
  is_trailer_interchange: boolean
}

export interface RouteWay {
  total_dur: number | null
  total_length: number | null
  way?: FlexiblePolyline | null
}

export interface AssigneeContact {
  id: Profile['id']
  contact_methods: {
    method: AssigneeContactMethod
    value: string
  }[]
}

export enum GeoStateStatus {
  OK = 'ok',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
}

export interface GeoState {
  status: GeoStateStatus
  stage_polygons: {
    status: GeoStateStatus
    error: string
  }
  stage_route_way: {
    status: GeoStateStatus
    error: string
  }
  error_msg?: string
}

interface Organization {
  id: string
  name: string
}

export interface TransportInfo {
  type: TransportTypeSlug | null
  body: TruckBodySlug[]
  equipment: ExtraEquipmentSlug[]
  loading_type: LoadingTypeSlug[]
  shipping_mode: ShippingModeSlug[] | null
  is_trailer_interchange: boolean
  temperature_min_c: number | null
  temperature_max_c: number | null
  adr_class: AdrClassSlug[] | null
  available_weight: number | null
  available_volume: number | null
  length: number | null
  width: number | null
  height: number | null
}

export interface GetTemplatesRequest {
  page?: number
  records?: number
  type?: OfferType
  active_space_company_id?: Company['id'] | null
}

export interface GetOffersResponse {
  result: Offer[]
  total: number
  count: number
  page: number
  count_by_status: OfferInStatusCount[]

  // private partnership counters for incoming and outgoing offers
  overall_cargo?: number
  overall_transport?: number

  profiles?: Profile[]
  companies?: Company[]
  space_bids?: Bid[]
}

export interface OfferInStatusCount {
  count: number
  status: Exclude<OfferStatus, OfferStatus.DEAL_MADE | OfferStatus.DELETED>
}

export interface FilterGeo {
  osm_id: string
  osm_type: string
  radius: number | null
}

export interface GetPublicOffersRequest {
  offer_type?: OfferType

  page?: number
  records?: number
  sort?: 'asc' | 'desc'

  start_point?: FilterGeo | null
  end_point?: FilterGeo | null

  shipping_mode?: string | null
  transport_body?: TruckBodySlug[] | null
  transport_type?: TransportTypeSlug[] | null
  weight_min?: number | null
  weight_max?: number | null
  volume_min?: number | null
  volume_max?: number | null
  adr_class?: AdrClassSlug[] | null
  type_exact?: boolean | null

  date_start?: string | null // '2016-01-02'
  date_end?: string | null // 2016-01-02
}

interface UserOrCompanyData {
  id: string
  is_company?: boolean | null
}

export interface GetMyOffersBody extends GetPublicOffersRequest {
  assignee_ids?: string[] | null
  bidder_ids?: UserOrCompanyData[] | null
  bid_winner_ids?: UserOrCompanyData[] | null
  private_company_ids?: string[] | null
  private_group_ids?: string[] | null
  created_at_from?: string | null
  created_at_to?: string | null
  archived_at_from?: string | null
  archived_at_to?: string | null
  published_at_from?: string | null
  published_at_to?: string | null
  has_bids?: boolean | null
}

export interface GetMyOffersParams {
  type?: OfferType
  status?: OfferStatus[]
  offset?: number
  limit?: number
}

export interface SearchParams {
  q?: string
}

export interface GetOfferContactsRequest {
  active_space_company_id: string | null
  offer_id: BaseOffer['_id']
}

export type GetOfferContactsResponse = {
  profiles: Profile[]
  company_id: null | string
  companies: Company[]
  creation_profile_id: string
} & Pick<BaseOffer, 'assignee_users'>

export interface AssigneeResponse {
  id: string
  name: string
  avatar_uri?: string | null
  color_hex?: string | null
}

export interface UserOrCompanyResponse {
  id: string
  is_company: boolean
  name: string
  color_hex?: string | null
  avatar_uri?: string | null
}

export enum FileType {
  Excel = 'xlsx',
  Csv = 'csv',
  Json = 'json',
}

export interface MyExportParams {
  format: FileType,
  lang?: string | null
  status?: OfferStatus[] | null
  timezone?: number | null
  route_timezone?: number | null
}

export interface GetPrivateOffersRequest extends GetPublicOffersRequest {
  only_private: boolean
  private_incoming?: boolean
  private_outgoing?: boolean
  private_company_ids?: string[]
}

export interface GetPublicOfferRequest {
  id: Offer['_id']
  user_hash: string | null
}

export interface GetPublicOffersCompanyRequest extends ExchangePagination {
  offer_type?: OfferType
  companyId: string
}

export interface GetPublicOffersProfileRequest extends ExchangePagination {
  offer_type?: OfferType
  profileId: Profile['id']
}

export type GetTemplatesResponse = {
  count: number
  page: number
  total: number
  result: Array<Template>
}

export type CreateTemplateRequest = {
  name: string
  body: CreateTemplateBody
}

interface CreateTemplateBody extends CreateOfferRequestBase {
  company_id?: Company['id'] | null
}

export type Template = {
  id: ID
  name: string
  body: RecursivePartial<CreateOfferResponse>
}

export interface GetOfferResponse {
  result: Offer
  profiles: Profile[]
  company: Company | null
  space_bids: Bid[]
}

export type GetOfferRouteResponse = RouteWay

export type GetOfferGeoStateResponse = GeoState

export interface GetOfferMatchesRequest extends ExchangePagination {
  id: Offer['_id']

  exact: boolean
  polygon?: boolean
  way_polyline?: boolean
}

export interface GeoOfferMatchesResponse {
  count: number
  last_matched_at: string | null
  matches: Offer[]
  page: number
  total: number

  profiles?: Profile[]
  companies?: Company[]
}

/*
 * Type guards
 */
export const isCargoOffer = (offer: Pick<Offer, 'offer_type'>): offer is CargoOffer => offer.offer_type
  === OfferType.CARGO

export const isTransportOffer = (offer: Pick<Offer, 'offer_type'>): offer is TransportOffer => offer.offer_type
  === OfferType.TRANSPORT

/*
 * Type guards end
 */

export interface OfferBidsByCategory {
  won: Bid['id'] | null,
  active: Bid['id'][],
  inactive: Bid['id'][],
  lost: Bid['id'][],
}

export enum BidType {
  ALL = 'all',
  // LEADS = 'leads',
  BIDDING = 'bidding',
  CONFIRMATION = 'confirmation',
  AWAIT_CONFIRM = 'await_confirm',
  ARCHIVE = 'archive',
}

export enum BidMode {
  AD = 'ad',
  BIDDING = 'bidding',
  AUCTION = 'auction',
  FIX = 'fix',
}

export enum BidStatus {
  CREATED = 'created',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  ACCEPTED = 'accepted',
  CONFIRMED = 'confirmed',
  WON = 'won',
  ARCHIVED = 'archived',
}

export enum BidState {
  BEST = 'best',
  AMONG_BEST = 'among_best',
  AVERAGE = 'average',
  LOOSING = 'loosing',
  OUTBIDED = 'outbided',
}

export enum BidCategory {
  VAT = 'vat',
  NO_VAT = 'no_vat',
  MIXED = 'mixed',
}

export enum BidRejectReason {
  LOST_TO_ANOTHER = 'lost_to_another',
  CONFIRM_RUNOUT = 'confirm_runout',
  REJECT = 'reject',
  OFFER_CLOSED = 'offer_closed',
}

export interface BidsInfo {
  won_bid: Bid | null
  best_bid_vat: Bid | null
  best_bid_no_vat: Bid | null
  first_bid: Bid | null
  total_bids_vat: number
  total_bids_no_vat: number
  total_participants: number
  total_bids: number
}

export interface Bid {
  id: string
  assignee: Profile['id']
  bidder_order_place: number
  origin_offer_id: Offer['_id']
  linked_offer_id: Offer['_id'] | null
  status: BidStatus
  prev_status: BidStatus
  state: BidState
  category: BidCategory
  company_id: string | null
  reject_reason: BidRejectReason | null
  quote: {
    is_vat: boolean
    currency: CurrencySlug
    mileage_format: string | null
    comment: string | null
    price: string | null
    mileage_price: string | null
    universal_price: string
  }
  top_place: number | null
  is_archived: boolean // deprecated
  is_accepted_automatically: boolean | null
  rejected_at: ISOString | null
  cancelled_at: ISOString | null
  accepted_at: ISOString | null
  confirmed_at: ISOString | null
  won_at: ISOString | null
  bid_lifetime: number // seconds // indicates when bid must be cancelled (autocancel option)
  created_at: ISOString
  updated_at: ISOString
  status_updated_at: ISOString
  confirmation_deadline_at: ISOString | null
  archivation_deadline_at: ISOString | null

  delivery_days_lower_bound?: number | null
  delivery_days_upper_bound?: number | null
}

export interface BidCreateRequest {
  offerId: Offer['_id']
  body: {
    assignee_id?: Profile['id'] | null
    linked_offer_id?: Offer['_id'] | null
    company_id?: Company['id'] | null
    end_at?: ISOString | null

    delivery_days_lower_bound?: number | null
    delivery_days_upper_bound?: number | null

    quote: {
      is_vat: boolean
      currency: string // USD
      mileage_format?: string
      comment?: string | null
      mileage_price: string | null
      price?: string | null
    }
  }
}

export type BidCreateResponse = {
  bid: Bid
  offer: Offer
}

export interface BidChangeStatusRequest {
  offerId: Offer['_id']
  bidId: Bid['id']
  body: {
    status: BidStatus
    reason?: BidRejectReason
  }
}

export type BidChangeStatusResponse = {
  bid: Bid
  offer: Offer
}

export interface GetMyBidsRequest {
  type: BidType
  offset?: number
  limit?: number
}

export interface GetMyBidsResponse {
  bids: Bid[]
  companies: Company[]
  profiles: Profile[]
  offers: Offer[]
  total: number
  page: number
  count: number
  count_by_status: Record<BidType, number>
}

export interface GetOfferBidsRequest {
  offerId: Offer['_id']
}

export interface GetOfferBidsResponse {
  typed_bids: {
    won_bid: Bid
    active_bids: Bid[]
    non_active_bids: Bid[]
    lost_bids: Bid[]
    total: number
  }
  profiles: Profile[]
  companies: Company[]
}

export interface ProlongBidRequest {
  offerId: Offer['_id']
  bidId: Bid['id']
  body: {
    prolongate_duration_seconds: number // seconds
  }
}

export type ProlongBidResponse = BidChangeStatusResponse
